import { getDiscountedPrice, getDiscountedPriceByUnit, getPriceOnRateAndTax, getRateOnPrice, getTaxOnRate } from "../../Utils/General/DiscountCals"
import { numToFixed2, numToIfFixed2 } from "../../Utils/General/numManipulation"

export const tabNameHelperFunc = (subtab = 'sales', invoicetype = 'gstinvoice') => {

    const issales = ((subtab === 'sales') || (subtab === 'editsales'))
    const ispurchase = ((subtab === 'purchase') || (subtab === 'editpurchase'))
    const issalesorder = ((subtab === 'salesorder') || (subtab === 'editsalesorder'))
    const ispurchaseorder = ((subtab === 'purchaseorder') || (subtab === 'editpurchaseorder'))
    const issalesreutrn = ((subtab === 'salesreturn') || (subtab === 'editsalesreturn'))
    const ispurchasereturn = ((subtab === 'purchasereturn') || (subtab === 'editpurchasereturn'))

    const isSales = subtab.includes('sales')
    const isPurchase = subtab.includes('purchase')
    const isOrder = subtab.includes('order')
    const isReturn = subtab.includes('return')

    const parentSubTab = `${isSales ? 'sales' : 'purchase'}${isOrder ? 'order' : ''}${isReturn ? 'return' : ''}`
    const collname = `${isSales ? 'sales' : 'purchase'}${isOrder ? 'order' : ''}${isReturn ? 'return' : ''}`

    //help to create url
    const tabname = isSales ? 'sales' : 'purchase'
    const itype = invoicetype === 'gstinvoice' ? 'gst' : 'quoat'
    const subTabURL = `/inventory/invoice?action=${parentSubTab}`
    const reportURL = `/reports/${tabname}?coll=${collname}&type=${invoicetype}&report=${itype}${tabname}`

    const isedit = subtab.includes('edit')

    // const toReduceFromBalance = (ispurchase || issalesreutrn) ? true : ((issales || ispurchasereturn) ? false : false)
    const toReduceFromBalance = !!(ispurchase || issalesreutrn || ispurchaseorder)
    const toReduceVal = toReduceFromBalance ? -1 : 1

    return { issales, ispurchase, issalesorder, ispurchaseorder, issalesreutrn, ispurchasereturn, collname, isReturn, isOrder, isSales, isPurchase, parentSubTab, subTabURL, isedit, reportURL, toReduceFromBalance, toReduceVal }
}


const itemTaxCalc = (price = 0, quantity = 1, discount = 0, gstrate = 0, cessrate = 0) => {
    const cgstrate = Number(gstrate / 2).toFixed(2)
    const sgstrate = Number(gstrate / 2).toFixed(2)
    const totaltaxrate = Number(Number(gstrate) + Number(cessrate)).toFixed(2)
    // const total = getDiscountedPriceByUnit(price, discount, quantity)
    // const rate = getDiscountedPrice(getRateOnPrice(price, totaltaxrate), discount)
    // console.log({ rate, total, price, quantity, discount, gstrate, cessrate, totaltaxrate });
    const rate = getDiscountedPrice(getRateOnPrice(getDiscountedPrice(price, discount), totaltaxrate))
    const total = getPriceOnRateAndTax(rate * quantity, numToIfFixed2(gstrate, cessrate))
    const taxableamount = Number(rate * quantity).toFixed(2)
    const gst = getTaxOnRate(taxableamount, gstrate)
    const cgst = Number(gst / 2).toFixed(2)
    const sgst = Number(gst / 2).toFixed(2)
    const cess = getTaxOnRate(taxableamount, cessrate)
    const totaltax = getTaxOnRate(taxableamount, totaltaxrate)
    return { rate, total, taxableamount, gst, cess, cgst, sgst, cgstrate, sgstrate, totaltax, totaltaxrate }
}

export const invoiceSummaryCalc = (invoiceItmes = [], invoicePayment = {}, roundoff = 1) => {
    const { discount = 0, shipping = 0, other = 0 } = invoicePayment
    const summary = [...invoiceItmes].reduce((acc, _item) => {
        const item = { ..._item, ...itemTaxCalc(_item.price, _item.quantity, _item.discount, _item.gstrate, _item.cessrate) }
        acc.invoicetotal = Number(Number(acc.invoicetotal) + Number(item.total)).toFixed(2)
        // acc.invoicegrosstotal = Math.round((acc.invoicetotal - (discount) - (-shipping) - (-other)) / roundoff) * roundoff
        // acc.invoiceroundoffdiff = Number(acc.invoicegrosstotal - acc.invoicetotal).toFixed(2)
        acc.invoicetotaltax = Number(Number(acc.invoicetotaltax) + Number(item.totaltax)).toFixed(2)
        const savingsOnMRP = Number(((item.mrp || item.price) * item.quantity) - item.total)
        acc.invoicetotalsavingsonmrp = Number(Number(acc.invoicetotalsavingsonmrp) + Number(savingsOnMRP > 0 ? savingsOnMRP : 0)).toFixed(2)
        acc.invoicetotaldiscountonsaleprice = Number(Number(acc.invoicetotaldiscountonsaleprice) + Number((item.price * item.quantity) - item.total)).toFixed(2)
        acc.invoicetaxableamount = Number(Number(acc.invoicetaxableamount) + Number(item.taxableamount)).toFixed(2)
        acc.invoicetemptaxableamount = Number(acc.invoicetotal - acc.invoicetotaltax).toFixed(2)
        const hsncode = item.hsncode || '-'
        if (!!hsncode) {
            if (!(acc.invoicehsnbasedtax[hsncode]))
                acc.invoicehsnbasedtax[hsncode] = { cgstrate: item.cgstrate, sgstrate: item.sgstrate, gstrate: item.gstrate, cessrate: item.cessrate, cgst: 0, sgst: 0, gst: 0, cess: 0, totaltax: 0, taxableamount: 0 }
            const { gst, cess, cgst, sgst, totaltax, taxableamount } = acc.invoicehsnbasedtax[hsncode]
            const temptaxdata = {
                cgst: Number(Number(cgst) + Number(item.cgst)).toFixed(2),
                sgst: Number(Number(sgst) + Number(item.sgst)).toFixed(2),
                gst: Number(Number(gst) + Number(item.gst)).toFixed(2),
                cess: Number(Number(cess) + Number(item.cess)).toFixed(2),
                totaltax: Number(Number(totaltax) + Number(item.totaltax)).toFixed(2),
                taxableamount: Number(Number(taxableamount) + Number(item.taxableamount)).toFixed(2),
            }
            acc.invoicehsnbasedtax[hsncode] = { ...acc.invoicehsnbasedtax[hsncode], ...temptaxdata }
            acc.invoiceratebasedtax[item.gstrate] = {
                sgst: numToFixed2(acc?.invoiceratebasedtax?.[item.gstrate]?.sgst ?? 0, item.sgst),
                cgst: numToFixed2(acc?.invoiceratebasedtax?.[item.gstrate]?.cgst ?? 0, item.cgst),
                gst: numToFixed2(acc?.invoiceratebasedtax?.[item.gstrate]?.gst ?? 0, item.gst),
                cess: numToFixed2(acc?.invoiceratebasedtax?.[item.gstrate]?.cess ?? 0, item.cess),
                totaltax: numToFixed2(acc?.invoiceratebasedtax?.[item.gstrate]?.totaltax ?? 0, item.totaltax),
                taxableamount: numToFixed2(acc?.invoiceratebasedtax?.[item.gstrate]?.taxableamount ?? 0, item.taxableamount),
            }
        }
        return { ...acc }
    }, {
        invoicetotal: 0,
        invoicetotaltax: 0,
        invoicetotalsavingsonmrp: 0,
        invoicetotaldiscountonsaleprice: 0,
        invoicetaxableamount: 0,
        invoicetemptaxableamount: 0,
        // invoicegrosstotal: 0,
        // invoiceroundoffdiff: 0,
        invoicehsnbasedtax: {},
        invoiceratebasedtax: {},
    })

    const invoicetotal = Number(summary.invoicetotal - discount - (-shipping) - (-other)).toFixed(2)
    const invoicegrosstotal = Math.round(invoicetotal / roundoff) * roundoff
    const invoiceroundoffdiff = Number(invoicegrosstotal - summary.invoicetotal).toFixed(2)

    return { ...summary, invoicetotal, invoicegrosstotal, invoiceroundoffdiff }
}