import { useRecoilValue } from "recoil"
import { viewCustomerPDFContent } from "./customerReportPDFContent"
import { invoiceReceiptA4_1 } from "./invoicePDFContent"
import { invoiceItemByPartySmartPDFContent } from "./invoiceReportPDFContent"
import { lineSalesStockPDFContent } from "./lineSalesReportPDFContent"
import { businessDataState } from "../../../../Recoil/Business/Atom"
import { cuttingJobCompletedEmployeeWisePDFContent, cuttingJobCompletedPDFContent } from "./Cutting/cuttingPDFContent"
import { businessLogoForReceiptState, invoiceSettingsState, itemSettingsState, receiptSettingsState, settingsFromDbState } from "../../../../Recoil/Settings/Atoms"
import { serviceInvoicePDF1 } from "./Invoice/Service/ServiceInvoicePDF1"
import { serviceInvoicePDF2 } from "./Invoice/Service/ServiceInvoicePDF2"
import { A5PDFInvoice1 } from "./Invoice/A5PDF/A5PDFInvoice1"
import { OpticalA5Format1 } from "./Invoice/OpticalPDF/OpticalA5Format1"
import { paymentsDBState } from "../../../../Recoil/Defaults/Atoms"

export default function usePDFContent() {
    const businessData = useRecoilValue(businessDataState)
    const itemSettings = useRecoilValue(itemSettingsState)
    const businessLogo = useRecoilValue(businessLogoForReceiptState)
    // const receiptSettings = useRecoilValue(receiptSettingsState)
    const { RECEIPTSETTINGS } = useRecoilValue(settingsFromDbState)
    const { invoiceFormatSetting } = useRecoilValue(invoiceSettingsState)
    const paymentTypes = useRecoilValue(paymentsDBState)

    const getCustomerContent = (reporttype = 'view', data) => {
        switch (reporttype) {
            case 'view': return { watermark: '', ...viewCustomerPDFContent(data) }
            default: return {}
        }
    }

    const getInvoiceContent = (reporttype = '', data) => {
        switch (reporttype) {
            case 'party10': return invoiceItemByPartySmartPDFContent(data)
            case 'a4format1': return invoiceReceiptA4_1({ ...data, businessData, itemSettings, businessLogo, receiptSettings: RECEIPTSETTINGS });
            case 'a4format2': return serviceInvoicePDF1({ ...data, businessData, itemSettings, businessLogo })
            case 'a4format3': return serviceInvoicePDF2({ invoiceFormatSetting, ...data, businessData, itemSettings, businessLogo })
            case 'a5format1': return A5PDFInvoice1({ invoiceFormatSetting, ...data, businessData, itemSettings, businessLogo })
            case 'opticala5format1': return OpticalA5Format1({ invoiceFormatSetting, ...data, businessData, itemSettings, businessLogo, paymentTypes, receiptSettings: RECEIPTSETTINGS })
            default: return invoiceReceiptA4_1({ ...data, businessData, itemSettings, businessLogo, receiptSettings: RECEIPTSETTINGS });
        }
    }

    const getLineSalesContent = (reporttype = '', data) => {
        switch (reporttype) {
            case 'stock': return lineSalesStockPDFContent(data)
            default: return {}
        }
    }

    const getCuttingContent = (reporttype = '', data) => {
        switch (reporttype) {
            case 'completedjob': return cuttingJobCompletedPDFContent(data)
            case 'completedjobempwise': return cuttingJobCompletedEmployeeWisePDFContent(data)
            default: return {}
        }
    }

    const getContent = (data, category = 'customer', reporttype = 'view') => {
        switch (category) {
            case 'customer': return getCustomerContent(reporttype, data)
            case 'invoice': return getInvoiceContent(reporttype, data)
            case 'linesales': return getLineSalesContent(reporttype, data)
            case 'cutting': return getCuttingContent(reporttype, data)
            default: return {}
        }
    }

    return getContent
}
