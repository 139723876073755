export const orderDefaultSettingDefaultData = {
    skipdefaultorder: false,
    //skipitemifdeleted: true, //by checking avlid
}

export const orderGeneralSettingDefaultData = {
    invoiceasorder: false,
    orderdate: 1, //order data from today: if 1 means, 1 day from today, 0 means today, -1 means yesterday
    orderdelivery: 10, //order delivery from today: if 1 means, 1 day from today, 0 means today, -1 means yesterday
}

export const orderSettingsDefaultData = {
    orderGeneralSetting: orderGeneralSettingDefaultData,
    orderDefaultSetting: orderDefaultSettingDefaultData,
}