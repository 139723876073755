import { atom } from "recoil";
import { receiptBuyerDefaultData, receiptInvoiceDetailsDefaultData, receiptItemsColumnsDefaultData, receiptSellerDefaultData, receiptTaxDefaultData, receiptTokenDefaultData } from "../../Components/Admin/ReceiptSettings/receiptSettingsDefaultData";
import { thermalReceiptHeaderFooterInitialData } from "../../Components/Admin/ReceiptSettings/ThermalReceiptSettings/thermalReceiptIntitialData";
import { defaultAppSettings } from "../../Components/Settings/AppSettings/AppSettingsDefaultData";
import { automationSettingsDefaultData } from "../../Components/Settings/AutomationSettings/automationSettingsDefaultData";
import { defaultInvoiceSettings } from "../../Components/Settings/InvoiceSettings/invoiceSettingsDefaultData";
import { defaultItemSettings } from "../../Components/Settings/ItemSettings/itemSettingsDefaultData";
import { orderSettingsDefaultData } from "../../Components/Settings/OrderSettings/OrderSettingsDefaultData";
import { defaultCuttingSettings } from "../../Components/Settings/CuttingSettings/CuttingSettingsDefaultData";
import { lineSalesSettingsDefaultData } from "../../Components/Settings/LineSalesSettings/LineSalesSettingsDefaultData";

export const settingsFromDbState = atom({
    key: 'settingsFromDbState',
    default: {}
})

export const invoiceSettingsState = atom({
    key: 'invoiceSettingsState',
    default: defaultInvoiceSettings,
})

export const receiptSettingsState = atom({
    key: 'receiptSettingsState',
    default: {
        thermalHeaderSetting: [thermalReceiptHeaderFooterInitialData()],
        thermalFooterSetting: [thermalReceiptHeaderFooterInitialData()],
        receiptInvoiceDataSetting: receiptInvoiceDetailsDefaultData,
        receiptSellerDataSetting: receiptSellerDefaultData,
        receiptBuyerDataSetting: receiptBuyerDefaultData,
        receiptItemColumnsSetting: receiptItemsColumnsDefaultData,
        receiptTaxDataSetting: receiptTaxDefaultData,
        receiptTokenSetting: receiptTokenDefaultData,
    }
})

export const businessLogoForReceiptState = atom({
    key: 'businessLogoForReceiptState',
    default: '',
})

export const orderSettingsState = atom({
    key: 'orderSettingsState',
    default: orderSettingsDefaultData
})

export const saleUnitsSettingState = atom({
    key: 'saleUnitsSettingState',
    default: {}
})

export const itemSettingsState = atom({
    key: 'itemSettingsState',
    default: defaultItemSettings
})

export const appSettingsState = atom({
    key: 'appSettingsState',
    default: defaultAppSettings,
})

export const lineSalesSettingsState = atom({
    key: 'lineSalesSettingsState',
    default: lineSalesSettingsDefaultData,
})

export const automationSettingsState = atom({
    key: 'automationSettingsState',
    default: automationSettingsDefaultData,
})

export const cuttingSettingsState = atom({
    key: 'cuttingSettingsState',
    default: defaultCuttingSettings,
})
