export const objToArr = (obj = {}, key = '__') => objReduce(obj, (acc, crr) => [...acc, { ...obj[crr], [key]: crr }], [])
export const objKeyToArr = (obj = {}) => Object.keys(obj)
export const getLenOfObj = (obj = {}) => objKeyToArr(obj).length
export const getLenOfObjBool = (obj = {}) => !!getLenOfObj(obj)
export const objReduce = (obj = {}, func, def = {}) => objKeyToArr(obj).reduce(func, def)
export const nObjFilter = (obj = {}, key = '0', compare = '') => objReduce(obj, (acc, crr) => obj[crr][key] === compare ? { ...acc, [crr]: { ...obj[crr] } } : { ...acc })

const checkEqual = (val1, val2) => val1 === val2
const checkNotEqual = (val1, val2) => val1 !== val2
const checkLessThen = (val1, val2) => val1 < val2
const checkGraterThen = (val1, val2) => val1 > val2
const checkLessThenEqual = (val1, val2) => val1 <= val2
const checkGraterThenEqual = (val1, val2) => val1 >= val2

export const objConditionCheck = (obj, filter) => {
    if (getLenOfObj(obj)) {
        if (filter.filterFunc) return filter.filterFunc(obj)
        const val1 = obj?.[filter.key] ?? (filter.undefinedas ?? '*_*')
        const val2 = filter.value ?? ''
        switch (filter.condition) {
            case '===': return checkEqual(val1, val2)
            case '!==': return checkNotEqual(val1, val2)
            case '<': return checkLessThen(val1, val2)
            case '>': return checkGraterThen(val1, val2)
            case '<=': return checkLessThenEqual(val1, val2)
            case '>=': return checkGraterThenEqual(val1, val2)
            default: return checkEqual(val1, val2)
        }
    }
    return false
}

export const objFilter = (obj = {}, filters = []) => getLenOfObjBool(obj) && filters.reduce((acc, filter) => acc && objConditionCheck(obj, filter), true)

export const nestedObjFilter = (obj = {}, filters = []) => getLenOfObj(obj) ? objKeyToArr(obj).filter(key => objFilter(obj[key], filters)) : {}

export const arrayOfObjFilter = (arr = [], filters = []) => arr.filter(obj => objFilter(obj, filters))

export const objToOptionList = (obj = {}, namekey = 'name', valuekey) => objReduce(obj, (acc, id) => ([...acc, { name: obj[id][namekey], value: obj?.[id]?.[valuekey] ?? id }]), [])

export const objHasNonEmptyValue = (data) => {
    if (Array.isArray(data)) {
        return data.length > 0 && data.some((value) => objHasNonEmptyValue(value));
    } else if (typeof data === 'object' && data !== null) {
        return Object.values(data).some((value) => objHasNonEmptyValue(value));
    }
    return data !== '';
};

//example array of obj params
//arr=[ {id: 1, name: 'gk'} , {id: 5, name: 'Annu'}, {id: 2, name: 'DCS'}]
//filter=[{key: 'name', condition: '===', value: 'gk'}, {key: 'id', condition: '<=', value: 5'}]
