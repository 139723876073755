const invoiceHeaderName = {
    'salesgstinvoice': 'Tax Invoice',
    'salesquotation': 'Quotation',
    'salesestimation': 'Estimation',
    'salesreturngstinvoice': 'Credit Note',
    'salesreturnquotation': 'Return Estimation',
    'purchasereturngstinvoice': 'Debit Note',
    'purchasereturnquotation': 'Purchase Return Estimation',
}

const invoiceItemCountByFont = {
    10: {
        single: 15,
        first: 33,
        mid: 33,
        last: 12,
    },
}

export const invoicePageHeader = ({ invoicetype, coll, title }) => {
    if (coll && invoicetype)
        return [
            { text: title ?? invoiceHeaderName?.[`${coll}${invoicetype}`] ?? 'Receipt', alignment: 'center', bold: true, margin: [0, 10, 0, 0], fontSize: 12 },
        ]

    return [
        { text: 'Receipt' }
    ]
}

export const invoicePageFooter = ({ currentPage, pageCount, receiptSettings = {} }) => {
    const { footer = {} } = receiptSettings
    const { subjected = '', adddata = '' } = footer
    return [
        {
            columns: [
                { text: `${subjected ? `Subjected to ${subjected} Jurisdiction` : ''}`, alignment: 'left', margin: [10, 0, 0, 0] },
                { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [10, 0, 10, 0] },
            ]
        },
        {
            columns: [
                { text: `${adddata ? adddata : ''}`, alignment: 'left', margin: [10, 0, 0, 0], fillColor: 'lightblue' },
                { text: 'Computer Generate Invoice.', alignment: 'right', margin: [10, 0, 10, 0] },
            ]
        },
    ];
}

export const invoicePageWaterMark = ({ receiptSettings = {} }) => {
    const { watermark = '' } = receiptSettings
    if (watermark)
        return { text: 'GK Angadi', opacity: 0.2, color: '#25356b' }
    return { text: '' }
}
